import { DSText } from '@zoe/ds-web'
import { Button } from '@/components/shared/Button'
import { LottieAnimation } from '@/components/shared/LottieAnimation'
import resultsGraphsMobile from '@/components/shared/lottie/resultsGraphsMobile.json'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import { decorateLinksWithQueryParameters } from '@/lib/utils/queryParams'
import { useEffect, useState } from 'react'
import { StructuredText } from 'react-datocms'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { ButtonRecord, HomePageHeroSection } from 'types'

type HomeHeroSectionProps = {
  content: HomePageHeroSection[]
  pageUrl: string
  queryParameters: { [key: string]: string }
}

const StyledBackground = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  padding-top: 80px;
  min-height: 680px;

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    flex-direction: column;
    min-height: auto;
    padding: 80px 48px 0 48px;
  }

  @media screen and (max-width: ${theme.breakpoints.mobile}) {
    padding: 80px 24px 0 24px;
  }
`

const StyledOverlay = styled.div<{ $bgColour: string }>`
  background-color: ${(props) => props.$bgColour};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  opacity: 0;

  &.active {
    opacity: 1;
  }
`

const StyledContentContainer = styled.div`
  z-index: 2;
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 90px;

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    width: auto;
    padding-top: 0;
    justify-content: flex-start;
  }
`

const StyledTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 380px;

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    display: block;
    max-width: none;
  }

  h1 {
    color: ${(props) => props.theme.v2.onSurface.text};
    font-size: ${(props) => props.theme.v2.typography.display.s.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.s.lineHeight};
    font-family: ${(props) => props.theme.v2.font.nantes};
    margin: 0;

    em {
      font-family: ${(props) => props.theme.v2.font.nantesItalic};
    }

    @media screen and (max-width: ${theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
    }
  }

  h2 {
    color: ${(props) => props.theme.v2.onSurface.text};
    font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    margin: 16px 0 0;

    em {
      font-family: ${(props) => props.theme.v2.font.nantesItalic};
    }

    @media screen and (max-width: ${theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
      margin-top: 8px;
    }
  }
`

const StyledGraphicsContent = styled.div`
  z-index: 1;
  position: relative;
  width: 60%;

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    height: 500px;
    width: auto;
    margin: 0 -48px 0 0;
  }

  @media screen and (max-width: ${theme.breakpoints.mobile}) {
    margin: 0 -24px 0 -24px;
  }

  .lottie {
    position: absolute;
    left: 130px;
    z-index: 2;

    @media screen and (max-width: ${theme.breakpoints.tablet}) {
      left: 0;
    }

    @media screen and (max-width: ${theme.breakpoints.mobile}) {
      left: 24px;
    }
  }
`

const StyledImageContent = styled.div<{ $bgImage: string }>`
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: ${(props) => `url("/assets/img/backgrounds/${props.$bgImage}.png")`};
  background-position: 20% 100%;
  background-size: auto;
  background-repeat: no-repeat;
  transition: opacity 750ms ease;
  opacity: 0;

  &.active {
    opacity: 1;
  }

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    background-image: ${(props) => `url("/assets/img/backgrounds/${props.$bgImage}-mobile.png")`};
    background-position: 100% 100%;
  }
`

const StyledButtonGroup = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;

  &.cta-buttons-desktop {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 32px;
  }

  &.button-group-mobile {
    display: none;
  }

  a {
    margin-bottom: 16px;
  }

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    align-items: flex-start;

    &.cta-buttons-desktop {
      display: none;
    }

    &.button-group-mobile {
      display: flex;
      position: absolute;
      bottom: 24px;
      left: 0;
      right: 0;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.mobile}) {
    align-items: unset;
    padding: 24px;

    &:last-child {
      padding-bottom: 0;
    }
  }
`

const SLIDES = [
  {
    slideNo: 0,
    backgroundColor: theme.v2.surface.yellow,
    imageBaseName: 'person1',
    alt: 'Middle-aged woman',
  },
  {
    slideNo: 1,
    backgroundColor: theme.v2.custom.homepageSecondaryYellow,
    imageBaseName: 'person2',
    alt: 'Young woman',
  },
  {
    slideNo: 2,
    backgroundColor: theme.v2.surface.yellow,
    imageBaseName: 'person3',
    alt: 'Elderly lady',
  },
  {
    slideNo: 3,
    backgroundColor: theme.v2.custom.homepageTertiaryYellow,
    imageBaseName: 'person4',
    alt: 'Middle-aged man',
  },
]

const SLIDE_PRESENTATION_TIME_MS = 4950

const trackButtonClick = (btnName: string) => {
  trackCtaClick('homepage', btnName)
}

const getH1 = ({ variant }) => {
  switch (variant) {
    case 'A':
    default:
      return (
        <h1>
          Understand how food affects <em>your body.</em>
        </h1>
      )
    case 'B':
      return (
        <h1>
          Make the smartest food choices <em>for you</em>
        </h1>
      )
    case 'C':
      return (
        <h1>
          Add more to your life. <em>Foods. Energy. Years.</em>
        </h1>
      )
    case 'D':
      return (
        <h1>
          Your daily coach to <em>better health</em>
        </h1>
      )
    case 'E':
      return (
        <h1>
          Are you ready to transform your health <em>for life?</em>
        </h1>
      )
  }
}
export const HomeHeroSectionOriginal = ({ content, pageUrl, queryParameters }: HomeHeroSectionProps) => {
  const { isReady, checkoutCountry, defaultCheckoutCountry } = useCheckoutCountry()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [ctaLinks, setCtaLinks] = useState<ButtonRecord[]>([])
  const [heroSection, setHeroSection] = useState<HomePageHeroSection>(content[0])

  useEffect(() => {
    const country = checkoutCountry || defaultCheckoutCountry

    if (isReady) {
      const localisedHeroSection = content.find((record) => record.localisation[0].country === country)
      localisedHeroSection ? setHeroSection(localisedHeroSection) : undefined
    }
  }, [isReady, checkoutCountry, defaultCheckoutCountry, content])

  useEffect(() => {
    const links = (heroSection.cta[0].buttons || []).map((ctaLink) => ({
      ...ctaLink,
      link: decorateLinksWithQueryParameters(ctaLink.link, pageUrl, queryParameters),
    }))
    setCtaLinks(links)
  }, [pageUrl, queryParameters, heroSection])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % SLIDES.length)
    }, SLIDE_PRESENTATION_TIME_MS)

    return () => {
      clearInterval(intervalId)
    }
  })

  useEffect(() => {
    // Needed to prevent unsyncing of animation due to use of back-forward cache on mobile browsers
    // https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked/13123626
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
      }
    }
  }, [])

  return (
    <StyledBackground>
      <StyledContentContainer>
        <StyledTextContent>
          <StructuredText data={heroSection.headline} />
          <StyledButtonGroup className="cta-buttons-desktop">
            {ctaLinks.map((button) => (
              <Button
                key={button.label}
                url={button.link}
                text={button.label}
                // style={button.buttonType}
                style="dark"
                onClick={() => trackButtonClick(`${button.label}-hero-section`)}
                withArrow
              />
            ))}
          </StyledButtonGroup>
        </StyledTextContent>
      </StyledContentContainer>

      <StyledGraphicsContent>
        <LottieAnimation
          ariaLabel="Personalised results indicators for gut microbiome, blood fat and blood sugar"
          role="figure"
          source={resultsGraphsMobile}
          className="lottie"
        />

        {SLIDES.map((slide, index) => (
          <StyledImageContent
            $bgImage={slide.imageBaseName}
            className={`${index === currentSlide ? 'active' : null}`}
            key={index}
          ></StyledImageContent>
        ))}

        <StyledButtonGroup className="button-group-mobile">
          {ctaLinks.map((button) => (
            <Button
              key={button.label}
              url={button.link}
              text={button.label}
              // style={button.buttonType}
              style="dark"
              onClick={() => trackButtonClick(`${button.label}-hero-section`)}
              withArrow
            />
          ))}
        </StyledButtonGroup>
      </StyledGraphicsContent>

      {SLIDES.map((slide, index) => (
        <StyledOverlay
          $bgColour={slide.backgroundColor}
          className={`${index === currentSlide ? 'active' : null}`}
          key={index}
        />
      ))}
    </StyledBackground>
  )
}
